




























































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'

@Component({
  components: { Uploader, TextEditor }
})

export default class BuildingEditOptionSettingsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  modularCarcassColumns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Optie - Commerciële naam',
      key: 'name',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_options.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'Bouwnummer prijs',
      key: 'building_options.price',
      scopedSlots: { customRender: 'building_price' }
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' }
    },
    {
      title: 'Te wijzigen',
      key: 'configurable',
      scopedSlots: { customRender: 'configurable' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Optie - Commerciële naam',
      key: 'name',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_options.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'Bouwnummer prijs',
      key: 'building_options.price',
      scopedSlots: { customRender: 'building_price' }
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  downloading = false
  modalVisible = false
  buildingOptions = {
    modular: [],
    carcass: [],
    completion2: [],
    completion3: []
  }

  defaultOptionValues:{ [key: string]: string|number|null } = {
    building_id: null,
    subelement_id: null,
    subelement_position: null,
    option_id: null,
    external_ref: null,
    name: null,
    name_sales: null,
    base_price: null,
    base_purchase_price: null,
    project_price: null,
    project_purchase_price: null,
    building_option_name: null,
    building_option_name_sales: null,
    building_price: null,
    building_purchase_price: null,
    building_text_sales: null,
    building_default: 0,
    vat_rate: null,
    phase: 0
  }

  currentOption = this.defaultOptionValues

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingOptions()
    }
  }

  getBuildingOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options').then((res) => {
      this.buildingOptions = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  showModal (item:Option, subelement:{id:number, position:number}|undefined) : void {
    this.modalVisible = true
    this.currentOption = {
      building_id: this.currentItem.id,
      subelement_id: subelement ? subelement.id : null,
      subelement_position: subelement ? subelement.position : null,
      option_id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      base_price: item.price,
      base_purchase_price: item.purchase_price,
      project_price: item.project_options && item.project_options.price !== null ? item.project_options.price : item.price,
      project_purchase_price: item.project_options && item.project_options.purchase_price !== null ? item.project_options.purchase_price : item.purchase_price,
      building_option_name: item.building_options && item.building_options.name !== null ? item.building_options.name : (item.project_options && item.project_options.name !== null ? item.project_options.name : item.name),
      building_option_name_sales: item.building_options && item.building_options.name_sales !== null ? item.building_options.name_sales : (item.project_options && item.project_options.name_sales !== null ? item.project_options.name_sales : item.name_sales),
      building_price: item.building_options && item.building_options.price !== null ? item.building_options.price : (item.project_options && item.project_options.price !== null ? item.project_options.price : item.price),
      building_purchase_price: item.building_options && item.building_options.purchase_price !== null ? item.building_options.purchase_price : (item.project_options && item.project_options.purchase_price !== null ? item.project_options.purchase_price : item.purchase_price),
      building_text_sales: item.building_options && item.building_options.text_sales !== null ? item.building_options.text_sales : (item.project_options && item.project_options.text_sales !== null ? item.project_options.text_sales : item.text_sales),
      building_default: item.building_options && item.building_options.default !== null ? item.building_options.default : (item.project_options && item.project_options.default !== null ? item.project_options.default : 0),
      vat_rate: item.building_options && item.building_options.vat_rate !== null ? item.building_options.vat_rate : (item.project_options && item.project_options.vat_rate !== null ? item.project_options.vat_rate : item.vat_rate),
      phase: item.phase
    }
  }

  hideModal () : void {
    this.modalVisible = false
    this.currentOption = this.defaultOptionValues
  }

  storeOption () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options', this.currentOption).then((res) => {
      this.buildingOptions = res.data
      this.hideModal()
      notification.success({ message: 'Optie instellingen opgeslagen.', description: 'De optie instellingen zijn succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Optie instellingen NIET opgeslagen.', description: 'De optie instellingen zijn NIET opgeslagen.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  deleteOption (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options/' + id).then((res) => {
      this.buildingOptions = res.data
      this.hideModal()
      notification.success({ message: 'Optie instelling gereset.', description: 'De optie instelling is succesvol gereset.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Optie instelling is NIET gereset.', description: 'De optie instelling is NIET gereset.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  contentChanged (key:string, value:string) : void {
    this.currentOption[key] = value
  }

  downloadBuildingOptions () : void {
    this.downloading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options/export').then((res) => {
      window.open(res.data, '_blank')
    }).finally(() => {
      this.downloading = false
    })
  }

  newFileUploaded (file:File) : void {
    this.$message.info('Upload gestart..', 3)

    const formData = new FormData()
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options/import', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      notification.success({ message: 'Upload succesvol.', description: 'De bouwnummer opties zijn succesvol opgeslagen.', duration: 3 })
      this.getBuildingOptions()
    }).catch(() => {
      notification.error({ message: 'Upload mislukt.', description: 'De bouwnummer opties zijn NIET opgeslagen. Probeer het later nog eens.' })
    })
  }

  toggleOption (optionId:number, configurable:boolean) : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/options/' + optionId + '/toggle', {
      disabled: !configurable
    }).catch(() => {
      this.buildingOptions.modular.forEach((o:{ id:number, configurable:boolean }) => { if (o.id === optionId) o.configurable = !configurable })
      this.buildingOptions.carcass.forEach((o:{ id:number, configurable:boolean }) => { if (o.id === optionId) o.configurable = !configurable })
      notification.error({ message: 'Fout tijdens het wijzigen van de optie!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    })
  }

  toggleSubelement (subelementId:number, configurable:boolean) : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/subelements/' + subelementId + '/toggle', {
      disabled: !configurable
    }).catch(() => {
      this.buildingOptions.completion2.forEach((se:{ id:number, configurable:boolean }) => {
        if (se.id === subelementId) {
          se.configurable = !configurable
        }
      })
      notification.error({ message: 'Fout tijdens het wijzigen van het subelement!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
