import Feedback from '@/types/feedback'

export default class Release {
    [key: string]: any;
    public id!:number
    public version = ''
    public feedbacks:Feedback[] = []
    public logs = ''

    public released_by!:string
    public released_at = ''
    public created_at!:string
    public updated_at!:string
}

export const PageModes = {
  create: 'create',
  edit: 'edit',
  view: 'view'
}
