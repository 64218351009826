
















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import Column from '@/types/column'
import GuardCode from '@/types/guardCode'
import { notification } from 'ant-design-vue'
import { units } from '@/globalValues'
import { LayoutBudget } from '@/types/layout'
import AdditionalBudgetOverview from '@/components/budget/AdditionalBudgetOverview.vue'
import TableSettings from '@/types/tableSettings'
import Pagination from '@/types/pagination'

@Component({
  components: { AdditionalBudgetOverview }
})

export default class ProjectEditLayoutBudgetsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  tableSettings:TableSettings = new SecureLS({ isCompression: false }).get('projectLayoutBudgetsTableSettings') || {
    pagination: { current: 1, pageSize: 25 },
    showSizeChanger: true,
    pageSizeOptions: ['25', '50', '100', '250', '500'],
    activeFilters: null,
    sort: null,
    search: null
  }

  columns:Column[] = [
    {
      title: 'Layoutnr.',
      key: 'external_ref',
      dataIndex: 'external_ref'
    },
    {
      title: 'Layout',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Prijs (incl. BTW)',
      key: 'price',
      scopedSlots: { customRender: 'price' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prijs (excl. BTW)',
      key: 'price_ex_vat',
      scopedSlots: { customRender: 'price_ex_vat' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      dataIndex: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Budget (ex. BTW)',
      key: 'budget',
      scopedSlots: { customRender: 'budget' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Opslagen (ex. BTW)',
      key: 'additional_budget',
      scopedSlots: { customRender: 'additional_budget' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Marge (ex. BTW)',
      key: 'margin',
      scopedSlots: { customRender: 'margin' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Rendement',
      key: 'profitability',
      scopedSlots: { customRender: 'profitability' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  layoutColumns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Omschrijving',
      key: 'description',
      scopedSlots: { customRender: 'description' }
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Eenheid',
      key: 'unit',
      scopedSlots: { customRender: 'unit' }
    },
    {
      title: 'Eenheidsprijs',
      key: 'unit_price',
      scopedSlots: { customRender: 'unit_price' }
    },
    {
      title: 'Totaalprijs',
      key: 'total_price',
      scopedSlots: { customRender: 'total_price' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  showModal = false
  searchTimer = 0
  loading = { items: false, search: false, saving: false, resetting: false }

  projectLayoutList:{ data: [], meta: { per_page:number, total:number } } = {
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  }

  guardCodes:GuardCode[] = []
  layoutBudget:{ id?:number, project_id:number, layout_id:number, guard_code_id:number|null, description:string|null, qty:number, unit:string, unit_price:number, total_price:number }|null = null

  mounted () : void {
    this.getProjectLayoutBudgets()
  }

  getProjectLayoutBudgets () : void {
    this.loading.items = true
    let url = `${process.env.VUE_APP_API_URL}/projects/${this.$route.params.projectId}/layoutBudgets`

    const addQueryParam = (key:string, value:string|number) => {
      url += url.includes('?') ? `&${key}=${value}` : `?${key}=${value}`
    }

    // Add filters
    if (this.tableSettings.activeFilters) {
      Object.entries(this.tableSettings.activeFilters).forEach(([key, value]) => {
        if (value.length) addQueryParam(`filter[${key}]`, value.join(','))
      })
    }

    // Add search
    if (this.tableSettings.search) {
      addQueryParam('filter[search]', this.tableSettings.search)
    }

    // Add sorting
    if (this.tableSettings.sort) {
      const sortKey = this.tableSettings.sort.order === 'ascend' ? this.tableSettings.sort.columnKey : `-${this.tableSettings.sort.columnKey}`
      addQueryParam('sort', sortKey)
    }

    // Add pagination
    if (this.tableSettings.pagination) {
      addQueryParam('limit', this.tableSettings.pagination.pageSize)
      addQueryParam('page', this.tableSettings.pagination.current)
    }

    axios.get(url).then((r) => {
      this.projectLayoutList = r.data
    }).finally(() => {
      this.loading.items = false
    })
  }

  toggleModal (layoutBudget:LayoutBudget|null = null, layoutId:number|null = null) : void {
    this.showModal = !this.showModal

    if (this.showModal && layoutBudget) {
      this.searchGuardCodes(layoutBudget.guard_code.code)
      this.layoutBudget = {
        id: layoutBudget.id,
        project_id: parseInt(this.$route.params.projectId),
        layout_id: layoutBudget.layout_id,
        guard_code_id: layoutBudget.guard_code_id,
        description: layoutBudget.description,
        qty: layoutBudget.qty,
        unit: layoutBudget.unit,
        unit_price: layoutBudget.unit_price,
        total_price: layoutBudget.total_price
      }
    } else if (this.showModal && !layoutBudget && layoutId) {
      this.layoutBudget = {
        project_id: parseInt(this.$route.params.projectId),
        layout_id: layoutId,
        guard_code_id: null,
        description: null,
        qty: 1,
        unit: 'PST',
        unit_price: 0,
        total_price: 0
      }
    } else if (!this.showModal) {
      this.layoutBudget = null
      this.guardCodes = []
    }
  }

  searchGuardCodes (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.searchTimer)

        this.searchTimer = setTimeout(() => {
          this.loading.search = true
          axios.get(process.env.VUE_APP_API_URL + '/guardCodes?filter[search]=' + val).then((r) => {
            this.guardCodes = r.data.data
          }).finally(() => {
            this.loading.search = false
          })
        }, 500)
      }
    }
  }

  onGuardCodeChange (id:number) : void {
    if (this.layoutBudget) {
      this.layoutBudget.guard_code_id = id

      const defaultUnit = this.guardCodes.find((guardCode:GuardCode) => guardCode.id === id)
      this.layoutBudget.unit = defaultUnit && defaultUnit.default_unit ? defaultUnit.default_unit : 'PST'
    }
  }

  calculateTotal () : void {
    if (this.layoutBudget) {
      if (this.layoutBudget.unit === '%') {
        this.layoutBudget.total_price = (this.layoutBudget.qty / 100) * this.layoutBudget.unit_price
      } else {
        this.layoutBudget.total_price = this.layoutBudget.qty * this.layoutBudget.unit_price
      }
    }
  }

  addLayoutBudget () : void {
    this.loading.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/projectLayoutBudgets', this.layoutBudget).then(() => {
      this.toggleModal()
      this.$emit('budgetChange')
      notification.success({ message: 'Budgetregel toegevoegd.', description: 'De budgetregel is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET toegevoegd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    }).finally(() => {
      this.loading.saving = false
    })
  }

  updateLayoutBudget () : void {
    this.loading.saving = true
    if (this.layoutBudget && this.layoutBudget.id) {
      axios.patch(process.env.VUE_APP_API_URL + '/projectLayoutBudgets/' + this.layoutBudget.id, this.layoutBudget).then(() => {
        this.toggleModal()
        this.$emit('budgetChange')
        notification.success({ message: 'Budgetregel bijgewerkt.', description: 'De budgetregel is succesvol bijgewerkt.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'Budgetregel NIET bijgewerkt.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
      }).finally(() => {
        this.loading.saving = false
      })
    }
  }

  deleteBudgetFromLayout (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/projectLayoutBudgets/' + id).then(() => {
      this.$emit('budgetChange')
      notification.success({ message: 'Budgetregel verwijderd.', description: 'De budgetregel is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET verwijderd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    })
  }

  resetProjectLayoutBudgets (layoutId:number) : void {
    this.loading.resetting = true
    axios.post(process.env.VUE_APP_API_URL + '/projectLayoutBudgets/reset', {
      projectId: this.$route.params.projectId,
      layoutId: layoutId
    }).then(() => {
      this.$emit('budgetChange')
      notification.success({ message: 'Budgetregels gereset.', description: 'De budgetregels zijn succesvol gereset.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregels NIET gereset.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    }).finally(() => {
      this.loading.resetting = false
    })
  }

  getProfitabilityClass (profitability: number) : string {
    if (profitability === 0) return ''
    if (profitability < 0) return 'lowProfitability'
    if (profitability < 10) return 'mediumProfitability'
    return ''
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.getProjectLayoutBudgets()
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.getProjectLayoutBudgets()
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: this.projectLayoutList.meta.per_page,
      total: this.projectLayoutList.meta.total,
      showTotal: (total, range) => `Project layout ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get editMode () : boolean {
    return !!(this.layoutBudget && this.layoutBudget.id)
  }

  get units () : { name:string, value:string }[] {
    return units
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set('projectLayoutBudgetsTableSettings', this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: 'projectLayoutBudgetsTableSettings',
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
