import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import CustomerModel from '@/models/CustomerModel'
import { notification } from 'ant-design-vue'
import router from '@/router'
import User from '@/types/user'
import Customer from '@/types/customer'

Vue.use(Vuex)

export const customerStore: Module<any, any> = {
  state: {
    customerList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentCustomer: new Customer(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentCustomer (state, { currentCustomer }) {
      state.currentCustomer = new Customer(currentCustomer)
    },
    clearCurrentCustomer (state) {
      state.currentCustomer = new Customer()
    },
    setCustomerList (state, { customerList }) {
      state.customerList = customerList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    userAddedToCustomer (state, { user }) {
      if (!state.currentCustomer.user_ids.includes(user.id)) {
        state.currentCustomer.user_ids.push(user.id)
        state.currentCustomer.users.push(user)
      }
    },
    userDeletedFromCustomer (state, { id }) {
      state.currentCustomer.user_ids.splice(state.currentCustomer.user_ids.indexOf(id), 1)

      let userIndex = null
      state.currentCustomer.users.forEach((user:User, index:number) => {
        if (user.id === id) {
          userIndex = index
        }
      })

      if (userIndex !== null) {
        state.currentCustomer.users.splice(userIndex, 1)
      }
    },
    contractorsChanged (state, { userId }) {
      if (!state.currentCustomer.contractor_ids.includes(userId)) {
        state.currentCustomer.contractor_ids.push(userId)
      } else {
        state.currentCustomer.contractor_ids.splice(state.currentCustomer.contractor_ids.indexOf(userId), 1)
      }
    },
    copyUserDetailsToCustomer (state, { index }) {
      state.currentCustomer.name = state.currentCustomer.users[index].full_name
      state.currentCustomer.reference = state.currentCustomer.users[index].full_name
      state.currentCustomer.phone = state.currentCustomer.users[index].phone
      state.currentCustomer.email = state.currentCustomer.users[index].email
      notification.success({ message: 'Gegevens gekopieerd.', description: 'De gegevens zijn gekopieerd. Vergeet de koper niet op te slaan.', duration: 3 })
    }
  },
  actions: {
    getCustomerList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let customerModel = new CustomerModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            customerModel = customerModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        customerModel = customerModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        customerModel = customerModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        customerModel = customerModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      customerModel.get().then((customerList) => {
        commit('setCustomerList', { customerList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de kopers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentCustomer ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        CustomerModel.$find(id).then((currentCustomer) => {
          commit('setCurrentCustomer', { currentCustomer })
          resolve(currentCustomer)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze koper!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    updateCustomer ({ commit }, currentCustomer) {
      commit('setCurrentCustomer', { currentCustomer })
    },
    clearCurrentCustomer ({ commit }) {
      commit('clearCurrentCustomer')
    },
    saveCustomer ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const customerModel = new CustomerModel(data)

      // Save customer.
      customerModel.save().then((currentCustomer) => {
        commit('setErrors', {})
        commit('setCurrentCustomer', { currentCustomer })
        if (data.id) {
          notification.success({ message: 'Koper opgeslagen.', description: 'De koper is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Koper toegevoegd.', description: 'De koper is succesvol toegevoegd.', duration: 3 })
          if (currentCustomer.id) {
            router.push({ name: 'customerEdit', params: { customerId: currentCustomer.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze koper!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze koper!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    userAddedToCustomer ({ commit }, user) {
      commit('userAddedToCustomer', { user })
    },
    userDeletedFromCustomer ({ commit }, id) {
      commit('userDeletedFromCustomer', { id })
    },
    contractorsChanged ({ commit }, userId) {
      commit('contractorsChanged', { userId })
    },
    copyUserDetailsToCustomer ({ commit }, index) {
      commit('copyUserDetailsToCustomer', { index })
    }
  },
  getters: {
    customerList: state => state.customerList,
    currentCustomer: state => state.currentCustomer,
    loadingCustomer: state => state.loading,
    customerErrors: state => state.errors
  }
}
