















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option from '@/types/option'
import User from '@/types/user'

@Component({
  components: {}
})

export default class OptionAnalyticsTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
