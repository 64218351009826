








































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building, {
  buildingCustomerMediaFolders,
  buildingMediaFolders,
  buildingTenantMediaFolders
} from '@/types/building'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'
import Filelist from '@/components/media/Filelist.vue'
import Project, { projectMediaFolders } from '@/types/project'
import Customer, { customerMediaFolders } from '@/types/customer'
import Tenant, { tenantMediaFolders } from '@/types/tenant'
import ConnectCustomer from '@/components/customers/ConnectCustomer.vue'
import ConnectTenant from '@/components/customers/ConnectTenant.vue'

@Component({
  components: { ConnectTenant, ConnectCustomer, Filelist, Uploader }
})

export default class BuildingDocumentsTab extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean

  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  // merge buildingMediaFolders with customerMediaFolders
  customerDirectories = customerMediaFolders
  tenantDirectories = tenantMediaFolders
  projectDirectories = projectMediaFolders

  activePanel:string = new SecureLS({ isCompression: false }).get('buildingEditDocumentsPanel') || null
  activeCustomerPanel:string = new SecureLS({ isCompression: false }).get('buildingEditDocumentsCustomerPanel') || null
  activeTenantPanel:string = new SecureLS({ isCompression: false }).get('buildingEditDocumentsTenantPanel') || null
  activeProjectPanel:string = new SecureLS({ isCompression: false }).get('buildingEditDocumentsProjectPanel') || null
  project:Project|null = null
  noPersonalDocumentsConfirmed = false

  mounted () : void {
    if (this.currentItem.project_id) {
      this.getProject()
    }
  }

  get buildingDirectories () : any[] {
    return this.currentItem.object_type === 1
      ? buildingCustomerMediaFolders.concat(buildingMediaFolders)
      : buildingTenantMediaFolders.concat(buildingMediaFolders)
  }

  get customer () : Customer|null {
    return this.currentItem.customer || null
  }

  get tenant () : Tenant|null {
    return this.currentItem.tenant || null
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  newFileUploaded (file:File, key:string, id: number, category: string, important = false, concept = false) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)
    formData.append('building_id', this.currentItem.id.toString())

    if (important) {
      formData.append('important', 'yes')
    }

    if (concept) {
      formData.append('concept', 'yes')
    }

    const url = `${process.env.VUE_APP_API_URL}/${category}/${id}/media`

    axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  onTenantAdded (tenant:Tenant) : void {
    if (tenant) {
      this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
    }
  }

  setActivePanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingEditDocumentsPanel', panel)
    this.activePanel = panel
  }

  setActiveCustomerPanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingEditDocumentsCustomerPanel', panel)
    this.activeCustomerPanel = panel
  }

  setActiveTenantPanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingEditDocumentsTenantPanel', panel)
    this.activeTenantPanel = panel
  }

  setActiveProjectPanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingEditDocumentsProjectPanel', panel)
    this.activeProjectPanel = panel
  }

  reloadBuilding () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  getProject () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.project_id).then((res) => {
      this.project = res.data.data
    })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
