





































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Layout from '@/types/layout'
import LayoutEditDetailsTab from '@/views/layout/tabs/edit/LayoutEditDetailsTab.vue'
import LayoutEditConfigTab from '@/views/layout/tabs/edit/LayoutEditConfigTab.vue'
import LayoutEditBuildingsTab from '@/views/layout/tabs/edit/LayoutEditBuildingsTab.vue'
import LayoutEditBudgetsTab from '@/views/layout/tabs/edit/LayoutEditBudgetsTab.vue'
import LayoutAnalyticsTab from '@/views/layout/tabs/LayoutAnalyticsTab.vue'

@Component({
  components: { LayoutAnalyticsTab, LayoutEditBudgetsTab, LayoutEditBuildingsTab, LayoutEditConfigTab, LayoutEditDetailsTab }
})

export default class LayoutEdit extends Vue {
  settings = {
    routeNames: { list: 'layouts', show: 'layoutShow', edit: 'layoutEdit' },
    routeParamKey: 'layoutId',
    functionNames: { setCurrent: 'setCurrentLayout', clearCurrent: 'clearCurrentLayout', save: 'saveLayout' },
    getterNames: { current: 'currentLayout', loading: 'loadingLayout' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('layoutEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('layoutEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Layout {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
