






































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import Company from '@/types/company'
import { Column } from 'ant-design-vue/types/table/column'

@Component({
  components: {}
})

export default class IssueBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]
  @Prop() tableSort!: { column: Column, columnKey: string, field: string, order:string } | null

  saving = false
  downloadCollection = false

  bulkUpdate:Record<string, (string|number|null)> = {
    status: null
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/questions/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Vragen bijgewerkt.', description: 'De vragen zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = {
          status: null
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }

  downloadCollectionPdf () : void {
    this.downloadCollection = true
    const payload: { selectedIds:number[], sorting: { column:string, order:string } | null } = {
      selectedIds: this.selectedIds,
      sorting: null
    }

    // If the table is not sorted, we don't want to send the sorting property
    if (this.tableSort?.column && this.tableSort?.order) {
      payload.sorting = {
        column: this.tableSort.columnKey,
        order: this.tableSort.order
      }
    }

    axios.post(process.env.VUE_APP_API_URL + '/questions/collection/export', payload, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Vragen_Overzicht.pdf'
      link.click()
    }).finally(() => {
      this.downloadCollection = false
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}
