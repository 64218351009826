import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { store } from '@/store'
import { notification } from 'ant-design-vue'

// General routes.
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Login from '@/views/Login.vue'
import O365LoginSucceeded from '@/views/O365LoginSucceeded.vue'
import Account from '@/views/Account.vue'

// Entity routes.
import Entities from '@/views/entity/Entities.vue'
import EntityShow from '@/views/entity/EntityShow.vue'
import EntityEdit from '@/views/entity/EntityEdit.vue'
import Companies from '@/views/company/Companies.vue'
import CompanyShow from '@/views/company/CompanyShow.vue'
import CompanyEdit from '@/views/company/CompanyEdit.vue'
import Users from '@/views/user/Users.vue'
import UserCreate from '@/views/user/UserCreate.vue'
import RoleCreate from '@/views/role/RoleCreate.vue'
import UserShow from '@/views/user/UserShow.vue'
import UserEdit from '@/views/user/UserEdit.vue'
import Roles from '@/views/role/Roles.vue'
import RoleShow from '@/views/role/RoleShow.vue'
import RoleEdit from '@/views/role/RoleEdit.vue'
import Projects from '@/views/project/Projects.vue'
import ProjectCreate from '@/views/project/ProjectCreate.vue'
import ProjectShow from '@/views/project/ProjectShow.vue'
import ProjectEdit from '@/views/project/ProjectEdit.vue'
import Buildings from '@/views/building/Buildings.vue'
import BuildingShow from '@/views/building/BuildingShow.vue'
import BuildingEdit from '@/views/building/BuildingEdit.vue'
import Customers from '@/views/customer/Customers.vue'
import CustomerCreate from '@/views/customer/CustomerCreate.vue'
import CustomerShow from '@/views/customer/CustomerShow.vue'
import CustomerEdit from '@/views/customer/CustomerEdit.vue'
import CustomerUsers from '@/views/customerUser/CustomerUsers.vue'
import CustomerUserEdit from '@/views/customerUser/CustomerUserEdit.vue'
import Tenants from '@/views/tenant/Tenants.vue'
import TenantCreate from '@/views/tenant/TenantCreate.vue'
import TenantShow from '@/views/tenant/TenantShow.vue'
import TenantEdit from '@/views/tenant/TenantEdit.vue'
import TenantUsers from '@/views/tenantUser/TenantUsers.vue'
import TenantUserCreate from '@/views/tenantUser/TenantUserCreate.vue'
import TenantUserEdit from '@/views/tenantUser/TenantUserEdit.vue'
import TenantUserShow from '@/views/tenantUser/TenantUserShow.vue'
import Layouts from '@/views/layout/Layouts.vue'
import LayoutShow from '@/views/layout/LayoutShow.vue'
import LayoutEdit from '@/views/layout/LayoutEdit.vue'
import Elements from '@/views/element/Elements.vue'
import ElementCreate from '@/views/element/ElementCreate.vue'
import ElementShow from '@/views/element/ElementShow.vue'
import ElementEdit from '@/views/element/ElementEdit.vue'
import Subelements from '@/views/subelement/Subelements.vue'
import SubelementCreate from '@/views/subelement/SubelementCreate.vue'
import SubelementShow from '@/views/subelement/SubelementShow.vue'
import SubelementEdit from '@/views/subelement/SubelementEdit.vue'
import Options from '@/views/option/Options.vue'
import OptionCreate from '@/views/option/OptionCreate.vue'
import OptionShow from '@/views/option/OptionShow.vue'
import OptionEdit from '@/views/option/OptionEdit.vue'
import BuildingConfigs from '@/views/buildingConfig/BuildingConfigs.vue'
import BuildingConfigShow from '@/views/buildingConfig/BuildingConfigShow.vue'
import Orders from '@/views/order/Orders.vue'
import OrderShow from '@/views/order/OrderShow.vue'
import Surveys from '@/views/survey/Surveys.vue'
import SurveyCreate from '@/views/survey/SurveyCreate.vue'
import SurveyShow from '@/views/survey/SurveyShow.vue'
import SurveyEdit from '@/views/survey/SurveyEdit.vue'
import ProjectWebsites from '@/views/projectwebsite/ProjectWebsites.vue'
import ProjectWebsiteEdit from '@/views/projectwebsite/ProjectWebsiteEdit.vue'
import Objections from '@/views/service/objections/Objections.vue'
import ObjectionEdit from '@/views/service/objections/ObjectionEdit.vue'
import QuestionsEdit from '@/views/service/questions/QuestionsEdit.vue'
import Issues from '@/views/service/issues/Issues.vue'
import IssueEdit from '@/views/service/issues/IssuesEdit.vue'
import Feedbacks from '@/views/feedback/Feedbacks.vue'
import FeedbackShow from '@/views/feedback/FeedbackShow.vue'
import FeedbackCreate from '@/views/feedback/FeedbackCreate.vue'
import CompanyCreate from '@/views/company/CompanyCreate.vue'
import IssuesCreate from '@/views/service/issues/IssuesCreate.vue'
import CustomerUserShow from '@/views/customerUser/CustomerUserShow.vue'
import CustomerUserCreate from '@/views/customerUser/CustomerUserCreate.vue'
import CompanyUsers from '@/views/companyUser/CompanyUsers.vue'
import CompanyUserCreate from '@/views/companyUser/CompanyUserCreate.vue'
import CompanyUserEdit from '@/views/companyUser/CompanyUserEdit.vue'
import CompanyUserShow from '@/views/companyUser/CompanyUserShow.vue'
import Questions from '@/views/service/questions/Questions.vue'
import BuildingCreate from '@/views/building/BuildingCreate.vue'
import ObjectionCreate from '@/views/service/objections/ObjectionCreate.vue'
import News from '@/views/news/News.vue'
import NewsEdit from '@/views/news/NewsEdit.vue'
import QuestionCreate from '@/views/service/questions/QuestionCreate.vue'
import ManualIndex from '@/views/manual/ManualIndex.vue'
import ManualPage from '@/views/manual/ManualPage.vue'
import ConstructionWorkerEdit from '@/views/constructionWorker/ConstructionWorkerEdit.vue'
import ConstructionWorkerShow from '@/views/constructionWorker/ConstructionWorkerShow.vue'
import MessageCreate from '@/views/message/MessageCreate.vue'
import Messages from '@/views/message/Messages.vue'
import MessageShow from '@/views/message/MessageShow.vue'
import Releases from '@/views/releases/Releases.vue'
import ReleaseDetails from '@/views/releases/ReleaseDetails.vue'
import { PageModes } from '@/types/release'
import GuardCodes from '@/views/guardCode/GuardCodes.vue'
import GuardCodeEdit from '@/views/guardCode/GuardCodeEdit.vue'
import GuardCodeShow from '@/views/guardCode/GuardCodeShow.vue'
import GuardCodeCreate from '@/views/guardCode/GuardCodeCreate.vue'
import Settings from '@/views/setting/Settings.vue'
import SurveyForms from '@/views/surveyForm/SurveyForms.vue'
import SurveyFormCreate from '@/views/surveyForm/SurveyFormCreate.vue'
import SurveyFormEdit from '@/views/surveyForm/SurveyFormEdit.vue'
import Deliveries from '@/views/delivery/Deliveries.vue'
import DeliveryShow from '@/views/delivery/DeliveryShow.vue'
import DevTools from '@/views/DevTools.vue'
import OldWoononOrders from '@/views/oldWoonon/order/OldWoononOrders.vue'
import OldWoononOrderShow from '@/views/oldWoonon/order/OldWoononOrderShow.vue'
import DemoConfigs from '@/views/demoConfig/DemoConfigs.vue'
import DemoConfigShow from '@/views/demoConfig/DemoConfigShow.vue'
import DiaryShow from '@/views/diary/DiaryShow.vue'
import ServiceReport from '@/views/reports/ServiceReport.vue'
import FivePercentRules from '@/views/fivePercent/FivePercentRules.vue'
import EventTriggerOverview from '@/views/communication/events/EventTriggerOverview.vue'
import Budgets from '@/views/budget/Budgets.vue'
import BudgetShow from '@/views/budget/BudgetShow.vue'
import BudgetBatchShow from '@/views/budget/BudgetBatchShow.vue'
import InvoiceRules from '@/views/invoiceRule/InvoiceRules.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/O365LoginSucceeded',
    name: 'O365LoginSucceeded',
    component: O365LoginSucceeded
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bedrijven',
    name: 'companies',
    component: Companies,
    meta: {
      requiresAuth: true,
      permission: 'companies.view'
    }
  },
  {
    path: '/bedrijf/toevoegen',
    name: 'companyCreate',
    component: CompanyCreate,
    meta: {
      requiresAuth: true,
      permission: 'companies.edit'
    }
  },
  {
    path: '/bedrijf/:companyId',
    name: 'companyShow',
    component: CompanyShow,
    meta: {
      requiresAuth: true,
      permission: 'companies.view'
    }
  },
  {
    path: '/bedrijf/:companyId/bewerken',
    name: 'companyEdit',
    component: CompanyEdit,
    meta: {
      requiresAuth: true,
      permission: 'companies.edit'
    }
  },
  {
    path: '/bedrijven/gebruikers',
    name: 'companyUsers',
    component: CompanyUsers,
    meta: {
      requiresAuth: true,
      permission: 'companies.users.view'
    }
  },
  {
    path: '/bedrijf/gebruiker/toevoegen',
    name: 'companyUserCreate',
    component: CompanyUserCreate,
    meta: {
      requiresAuth: true,
      permission: 'companies.users.edit'
    }
  },
  {
    path: '/bedrijf/gebruiker/:companyUserId/bewerken',
    name: 'companyUserEdit',
    component: CompanyUserEdit,
    meta: {
      requiresAuth: true,
      permission: 'companies.users.edit'
    }
  },
  {
    path: '/bedrijf/gebruiker/:companyUserId',
    name: 'companyUserShow',
    component: CompanyUserShow,
    meta: {
      requiresAuth: true,
      permission: 'companies.users.view'
    }
  },
  {
    path: '/entiteiten',
    name: 'entities',
    component: Entities,
    meta: {
      requiresAuth: true,
      permission: 'entities.view'
    }
  },
  {
    path: '/entiteit/:entityId',
    name: 'entityShow',
    component: EntityShow,
    meta: {
      requiresAuth: true,
      permission: 'entities.view'
    }
  },
  {
    path: '/entiteit/:entityId/bewerken',
    name: 'entityEdit',
    component: EntityEdit,
    meta: {
      requiresAuth: true,
      permission: 'entities.edit'
    }
  },
  {
    path: '/gebruikers',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
      permission: 'users.view'
    }
  },
  {
    path: '/gebruiker/toevoegen',
    name: 'userCreate',
    component: UserCreate,
    meta: {
      requiresAuth: true,
      permission: 'users.edit'
    }
  },
  {
    path: '/gebruiker/:userId',
    name: 'userShow',
    component: UserShow,
    meta: {
      requiresAuth: true,
      permission: 'users.view'
    }
  },
  {
    path: '/gebruiker/:userId/bewerken',
    name: 'userEdit',
    component: UserEdit,
    meta: {
      requiresAuth: true,
      permission: 'users.edit'
    }
  },
  {
    path: '/rollen',
    name: 'roles',
    component: Roles,
    meta: {
      requiresAuth: true,
      permission: 'roles.view'
    }
  },
  {
    path: '/rol/toevoegen',
    name: 'roleCreate',
    component: RoleCreate,
    meta: {
      requiresAuth: true,
      permission: 'roles.edit'
    }
  },
  {
    path: '/rol/:roleId',
    name: 'roleShow',
    component: RoleShow,
    meta: {
      requiresAuth: true,
      permission: 'roles.view'
    }
  },
  {
    path: '/rol/:roleId/bewerken',
    name: 'roleEdit',
    component: RoleEdit,
    meta: {
      requiresAuth: true,
      permission: 'roles.edit'
    }
  },
  {
    path: '/projecten',
    name: 'projects',
    component: Projects,
    meta: {
      requiresAuth: true,
      permission: 'projects.view'
    }
  },
  {
    path: '/project/toevoegen',
    name: 'projectCreate',
    component: ProjectCreate,
    meta: {
      requiresAuth: true,
      permission: 'projects.edit'
    }
  },
  {
    path: '/project/:projectId',
    name: 'projectShow',
    component: ProjectShow,
    meta: {
      requiresAuth: true,
      permission: 'projects.view'
    }
  },
  {
    path: '/project/:projectId/bewerken',
    name: 'projectEdit',
    component: ProjectEdit,
    meta: {
      requiresAuth: true,
      permission: 'projects.edit'
    }
  },
  {
    path: '/project/:projectId/uitvoerdersdagboek/:diaryId',
    name: 'diaryShow',
    component: DiaryShow,
    meta: {
      requiresAuth: true,
      permission: 'projects.diaries.view'
    }
  },
  {
    path: '/bouwnummers',
    name: 'buildings',
    component: Buildings,
    meta: {
      requiresAuth: true,
      permission: 'buildings.view'
    }
  },
  {
    path: '/bouwnummer/toevoegen',
    name: 'buildingCreate',
    component: BuildingCreate,
    meta: {
      requiresAuth: true,
      permission: 'buildings.edit'
    }
  },
  {
    path: '/bouwnummer/:buildingId',
    name: 'buildingShow',
    component: BuildingShow,
    meta: {
      requiresAuth: true,
      permission: 'buildings.view'
    }
  },
  {
    path: '/bouwnummer/:buildingId/bewerken',
    name: 'buildingEdit',
    component: BuildingEdit,
    meta: {
      requiresAuth: true,
      permission: 'buildings.edit'
    }
  },
  {
    path: '/bouwnummers/5-procent-regelingen',
    name: 'fivePercentRules',
    component: FivePercentRules,
    meta: {
      requiresAuth: true,
      permission: 'buildings.fivePercent.view'
    }
  },
  {
    path: '/kopers',
    name: 'customers',
    component: Customers,
    meta: {
      requiresAuth: true,
      permission: 'customers.view'
    }
  },
  {
    path: '/koper/toevoegen',
    name: 'customerCreate',
    component: CustomerCreate,
    meta: {
      requiresAuth: true,
      permission: 'customers.edit'
    }
  },
  {
    path: '/koper/:customerId',
    name: 'customerShow',
    component: CustomerShow,
    meta: {
      requiresAuth: true,
      permission: 'customers.view'
    }
  },
  {
    path: '/koper/:customerId/bewerken',
    name: 'customerEdit',
    component: CustomerEdit,
    meta: {
      requiresAuth: true,
      permission: 'customers.edit'
    }
  },
  {
    path: '/kopers/gebruikers',
    name: 'customerUsers',
    component: CustomerUsers,
    meta: {
      requiresAuth: true,
      permission: 'customers.users.view'
    }
  },
  {
    path: '/koper/gebruiker/toevoegen',
    name: 'customerUserCreate',
    component: CustomerUserCreate,
    meta: {
      requiresAuth: true,
      permission: 'customers.users.edit'
    }
  },
  {
    path: '/koper/gebruiker/:customerUserId/bewerken',
    name: 'customerUserEdit',
    component: CustomerUserEdit,
    meta: {
      requiresAuth: true,
      permission: 'customers.users.edit'
    }
  },
  {
    path: '/koper/gebruiker/:customerUserId',
    name: 'customerUserShow',
    component: CustomerUserShow,
    meta: {
      requiresAuth: true,
      permission: 'customers.users.view'
    }
  },
  {
    path: '/huurders',
    name: 'tenants',
    component: Tenants,
    meta: {
      requiresAuth: true,
      permission: 'tenants.view'
    }
  },
  {
    path: '/huurder/toevoegen',
    name: 'tenantCreate',
    component: TenantCreate,
    meta: {
      requiresAuth: true,
      permission: 'tenants.edit'
    }
  },
  {
    path: '/huurder/:tenantId',
    name: 'tenantShow',
    component: TenantShow,
    meta: {
      requiresAuth: true,
      permission: 'tenants.view'
    }
  },
  {
    path: '/huurder/:tenantId/bewerken',
    name: 'tenantEdit',
    component: TenantEdit,
    meta: {
      requiresAuth: true,
      permission: 'tenants.edit'
    }
  },
  {
    path: '/huurders/gebruikers',
    name: 'tenantUsers',
    component: TenantUsers,
    meta: {
      requiresAuth: true,
      permission: 'tenants.users.view'
    }
  },
  {
    path: '/huurder/gebruiker/toevoegen',
    name: 'tenantUserCreate',
    component: TenantUserCreate,
    meta: {
      requiresAuth: true,
      permission: 'tenants.users.edit'
    }
  },
  {
    path: '/huurder/gebruiker/:tenantUserId/bewerken',
    name: 'tenantUserEdit',
    component: TenantUserEdit,
    meta: {
      requiresAuth: true,
      permission: 'tenants.users.edit'
    }
  },
  {
    path: '/huurder/gebruiker/:tenantUserId',
    name: 'tenantUserShow',
    component: TenantUserShow,
    meta: {
      requiresAuth: true,
      permission: 'tenants.users.view'
    }
  },
  {
    path: '/layouts',
    name: 'layouts',
    component: Layouts,
    meta: {
      requiresAuth: true,
      permission: 'layouts.view'
    }
  },
  {
    path: '/layout/:layoutId',
    name: 'layoutShow',
    component: LayoutShow,
    meta: {
      requiresAuth: true,
      permission: 'layouts.view'
    }
  },
  {
    path: '/layout/:layoutId/bewerken',
    name: 'layoutEdit',
    component: LayoutEdit,
    meta: {
      requiresAuth: true,
      permission: 'layouts.edit'
    }
  },
  {
    path: '/elementen',
    name: 'elements',
    component: Elements,
    meta: {
      requiresAuth: true,
      permission: 'elements.view'
    }
  },
  {
    path: '/element/toevoegen',
    name: 'elementCreate',
    component: ElementCreate,
    meta: {
      requiresAuth: true,
      permission: 'elements.edit'
    }
  },
  {
    path: '/element/:elementId',
    name: 'elementShow',
    component: ElementShow,
    meta: {
      requiresAuth: true,
      permission: 'elements.view'
    }
  },
  {
    path: '/element/:elementId/bewerken',
    name: 'elementEdit',
    component: ElementEdit,
    meta: {
      requiresAuth: true,
      permission: 'elements.edit'
    }
  },
  {
    path: '/subelementen',
    name: 'subelements',
    component: Subelements,
    meta: {
      requiresAuth: true,
      permission: 'subelements.view'
    }
  },
  {
    path: '/subelement/toevoegen',
    name: 'subelementCreate',
    component: SubelementCreate,
    meta: {
      requiresAuth: true,
      permission: 'subelements.edit'
    }
  },
  {
    path: '/subelement/:subelementId',
    name: 'subelementShow',
    component: SubelementShow,
    meta: {
      requiresAuth: true,
      permission: 'subelements.view'
    }
  },
  {
    path: '/subelement/:subelementId/bewerken',
    name: 'subelementEdit',
    component: SubelementEdit,
    meta: {
      requiresAuth: true,
      permission: 'subelements.edit'
    }
  },
  {
    path: '/opties',
    name: 'options',
    component: Options,
    meta: {
      requiresAuth: true,
      permission: 'options.view'
    }
  },
  {
    path: '/optie/toevoegen',
    name: 'optionCreate',
    component: OptionCreate,
    meta: {
      requiresAuth: true,
      permission: 'options.edit'
    }
  },
  {
    path: '/optie/:optionId',
    name: 'optionShow',
    component: OptionShow,
    meta: {
      requiresAuth: true,
      permission: 'options.view'
    }
  },
  {
    path: '/optie/:optionId/bewerken',
    name: 'optionEdit',
    component: OptionEdit,
    meta: {
      requiresAuth: true,
      permission: 'options.edit'
    }
  },
  {
    path: '/bouwnummer-configuraties',
    name: 'buildingConfigs',
    component: BuildingConfigs,
    meta: {
      requiresAuth: true,
      permission: 'buildingconfigs.view'
    }
  },
  {
    path: '/bouwnummer-configuraties/:buildingConfigId',
    name: 'buildingConfigShow',
    component: BuildingConfigShow,
    meta: {
      requiresAuth: true,
      permission: 'buildingconfigs.view'
    }
  },
  {
    path: '/demo-configuraties',
    name: 'demoConfigs',
    component: DemoConfigs,
    meta: {
      requiresAuth: true,
      permission: 'buildingconfigs.view'
    }
  },
  {
    path: '/demo-configuratie/:demoConfigId',
    name: 'demoConfigShow',
    component: DemoConfigShow,
    meta: {
      requiresAuth: true,
      permission: 'buildingconfigs.view'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      requiresAuth: true,
      permission: 'orders.view'
    }
  },
  {
    path: '/orders/:orderId',
    name: 'orderShow',
    component: OrderShow,
    meta: {
      requiresAuth: true,
      permission: 'orders.view'
    }
  },
  {
    path: '/orders/oud',
    name: 'oldWoononOrders',
    component: OldWoononOrders,
    meta: {
      requiresAuth: true,
      permission: 'orders.view'
    }
  },
  {
    path: '/orders/oud/:oldWoononOrderId',
    name: 'oldWoononOrderShow',
    component: OldWoononOrderShow,
    meta: {
      requiresAuth: true,
      permission: 'orders.view'
    }
  },
  {
    path: '/keuringen',
    name: 'surveys',
    component: Surveys,
    meta: {
      requiresAuth: true,
      permission: 'surveys.view'
    }
  },
  {
    path: '/keuring/toevoegen',
    name: 'surveyCreate',
    component: SurveyCreate,
    meta: {
      requiresAuth: true,
      permission: 'surveys.edit'
    }
  },
  {
    path: '/keuring/:surveyId',
    name: 'surveyShow',
    component: SurveyShow,
    meta: {
      requiresAuth: true,
      permission: 'surveys.view'
    }
  },
  {
    path: '/keuring/:surveyId/bewerken',
    name: 'surveyEdit',
    component: SurveyEdit,
    meta: {
      requiresAuth: true,
      permission: 'surveys.edit'
    }
  },
  {
    path: '/voorschouwen-opleveringen',
    name: 'deliveries',
    component: Deliveries,
    meta: {
      requiresAuth: true,
      permission: 'deliveries.view'
    }
  },
  {
    path: '/voorschouw-oplevering/:deliveryId',
    name: 'deliveryShow',
    component: DeliveryShow,
    meta: {
      requiresAuth: true,
      permission: 'deliveries.view'
    }
  },
  {
    path: '/keuringsformulieren',
    name: 'surveyForms',
    component: SurveyForms,
    meta: {
      requiresAuth: true,
      permission: 'surveys.forms.view'
    }
  },
  {
    path: '/keuringsformulier/toevoegen',
    name: 'surveyFormCreate',
    component: SurveyFormCreate,
    meta: {
      requiresAuth: true,
      permission: 'surveys.forms.edit'
    }
  },
  {
    path: '/keuringsformulier/:surveyFormId',
    name: 'surveyFormShow',
    component: SurveyFormEdit,
    meta: {
      requiresAuth: true,
      permission: 'surveys.forms.view'
    }
  },
  {
    path: '/keuringsformulier/:surveyFormId/bewerken',
    name: 'surveyFormEdit',
    component: SurveyFormEdit,
    meta: {
      requiresAuth: true,
      permission: 'surveys.forms.edit'
    }
  },
  {
    path: '/projectwebsites',
    name: 'projectWebsites',
    component: ProjectWebsites,
    meta: {
      requiresAuth: true,
      permission: 'projectwebsites.view'
    }
  },
  {
    path: '/projectwebsites/:projectWebsiteId/bewerken',
    name: 'projectWebsiteEdit',
    component: ProjectWebsiteEdit,
    meta: {
      requiresAuth: true,
      permission: 'projectwebsites.edit'
    }
  },
  {
    path: '/releases',
    name: 'releaseIndex',
    component: Releases,
    props: {
      editable: false
    },
    meta: {
      requiresAuth: true,
      permission: 'releases.view'
    }
  },
  {
    path: '/releases/toevoegen',
    name: 'releaseCreate',
    component: ReleaseDetails,
    props: {
      mode: PageModes.create
    },
    meta: {
      requiresAuth: true,
      permission: 'releases.view'
    }
  },
  {
    path: '/releases/:releaseId/bewerken',
    name: 'releaseEdit',
    component: ReleaseDetails,
    props: {
      mode: PageModes.edit
    },
    meta: {
      requiresAuth: true,
      permission: 'releases.edit'
    }
  },
  {
    path: '/releases/:releaseId',
    name: 'releaseEdit',
    component: ReleaseDetails,
    props: {
      mode: PageModes.view
    },
    meta: {
      requiresAuth: true,
      permission: 'releases.view'
    }
  },
  {
    path: '/releases/latest',
    name: 'releaseShow',
    component: ReleaseDetails,
    props: {
      mode: PageModes.view,
      latest: true
    },
    meta: {
      requiresAuth: true,
      permission: 'releases.edit'
    }
  },
  {
    path: '/feedbacks',
    name: 'feedbacks',
    component: Feedbacks,
    meta: {
      requiresAuth: true,
      permission: 'feedbacks.view'
    }
  },
  {
    path: '/feedback/toevoegen/',
    name: 'feedbackCreate',
    component: FeedbackCreate,
    meta: {
      requiresAuth: true,
      permission: 'feedbacks.edit'
    }
  },
  {
    path: '/feedback/:parentId/taak',
    name: 'taskCreate',
    component: FeedbackCreate,
    props: (route) => ({ parentId: route.params.parentId }),
    meta: {
      requiresAuth: true,
      permission: 'feedbacks.edit'
    }
  },
  {
    path: '/feedback/:feedbackId',
    name: 'feedbackShow',
    component: FeedbackShow,
    meta: {
      requiresAuth: true,
      permission: 'feedbacks.view'
    }
  },
  {
    path: '/berichten',
    name: 'messages',
    component: Messages,
    meta: {
      requiresAuth: true,
      permission: 'messages.view'
    }
  },
  {
    path: '/bericht/toevoegen',
    name: 'messageCreate',
    component: MessageCreate,
    meta: {
      requiresAuth: true,
      permission: 'messages.edit'
    }
  },
  {
    path: '/bericht/:messageId',
    name: 'messageShow',
    component: MessageShow,
    meta: {
      requiresAuth: true,
      permission: 'messages.view'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vragen',
    name: 'questions',
    component: Questions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vragen/:questionId/bewerken',
    name: 'questionEdit',
    component: QuestionsEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vragen/toevoegen',
    name: 'questionCreate',
    component: QuestionCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bezwaren',
    name: 'objections',
    component: Objections,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bezwaren/:objectionId/bewerken',
    name: 'objectionEdit',
    component: ObjectionEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bezwaren/toevoegen',
    name: 'objectionCreate',
    component: ObjectionCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/woonon-berichten',
    name: 'eventMessages',
    component: EventTriggerOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws',
    name: 'news',
    component: News,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws/toevoegen',
    name: 'newsCreate',
    component: NewsEdit,
    props: { clear: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws/:newsItemId/bewerken',
    name: 'newsEdit',
    component: NewsEdit,
    props: { editable: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws/:newsItemId',
    name: 'newsShow',
    props: { editable: false },
    component: NewsEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/meldingen',
    name: 'issues',
    props: { rental: false },
    component: Issues,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help',
    name: 'manualIndex',
    props: { editable: false },
    component: ManualIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help/:collectionId/nieuw',
    name: 'manualNew',
    props: {
      editMode: true,
      newPage: true
    },
    component: ManualPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/help/:collectionId/:slug',
    name: 'manualPage',
    component: ManualPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/meldingen/tuvast',
    name: 'issuesRental',
    props: { rental: true },
    component: Issues,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/meldingen/toevoegen',
    name: 'issueCreate',
    component: IssuesCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/meldingen/:issueId/bewerken',
    name: 'issueEdit',
    component: IssueEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bouwplaatsmedewerker/:constructionWorkerId',
    name: 'constructionWorkerShow',
    component: ConstructionWorkerShow,
    meta: {
      requiresAuth: true,
      permission: 'construction_workers.view'
    }
  },
  {
    path: '/bouwplaatsmedewerker/:constructionWorkerId/bewerken',
    name: 'constructionWorkerEdit',
    component: ConstructionWorkerEdit,
    meta: {
      requiresAuth: true,
      permission: 'construction_workers.edit'
    }
  },
  {
    path: '/bewakingscodes',
    name: 'guardCodes',
    component: GuardCodes,
    meta: {
      requiresAuth: true,
      permission: 'guard_codes.view'
    }
  },
  {
    path: '/bewakingscode/toevoegen',
    name: 'guardCodeCreate',
    component: GuardCodeCreate,
    meta: {
      requiresAuth: true,
      permission: 'guard_codes.edit'
    }
  },
  {
    path: '/bewakingscode/:guardCodeId',
    name: 'guardCodeShow',
    component: GuardCodeShow,
    meta: {
      requiresAuth: true,
      permission: 'guard_codes.view'
    }
  },
  {
    path: '/bewakingscode/:guardCodeId/bewerken',
    name: 'guardCodeEdit',
    component: GuardCodeEdit,
    meta: {
      requiresAuth: true,
      permission: 'guard_codes.edit'
    }
  },
  {
    path: '/budgetten',
    name: 'budgets',
    component: Budgets,
    meta: {
      requiresAuth: true,
      permission: 'budgets.view'
    }
  },
  {
    path: '/budgetten/:projectId',
    name: 'budgetShow',
    component: BudgetShow,
    meta: {
      requiresAuth: true,
      permission: 'budgets.view'
    }
  },
  {
    path: '/budgetten/:projectId/batch/:budgetBatchId',
    name: 'budgetBatchShow',
    component: BudgetBatchShow,
    meta: {
      requiresAuth: true,
      permission: 'budgets.view'
    }
  },
  {
    path: '/factuurregels',
    name: 'invoiceRules',
    component: InvoiceRules,
    meta: {
      requiresAuth: true,
      permission: 'invoice_rules.view'
    }
  },
  {
    path: '/instellingen',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      permission: 'settings.edit'
    }
  },
  {
    path: '/devtools',
    name: 'devTools',
    component: DevTools,
    meta: {
      requiresAuth: true,
      permission: 'devtools.edit'
    }
  },
  {
    path: '/rapporten/service',
    name: 'service-reports',
    component: ServiceReport
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      // If a permission is needed.
      if (to.meta?.permission) {
        // Check if user has the right permissions.
        if (store.getters.authUser.permissions.includes(to.meta?.permission)) {
          next()
          return
        }
        notification.warning({ message: 'Toegang geweigerd!', description: 'U heeft niet de juiste rechten voor dit onderdeel.' })
        next('/')
      } else {
        next()
        return
      }
    }
    next('/')
  } else {
    next()
  }
})

export default router
