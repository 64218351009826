import ServiceItem from '@/types/serviceItem'
import MediaItem from '@/types/mediaItem'
import ServiceComment from '@/types/serviceComment'
import moment from 'moment/moment'

class Company {
  public id!:number
  public reference!:string
  public name!:string
}
class User {
  public id!:number|null
  public name!:string|null
  public full_name!:string|null
  public email!:string|null
  public phone!:string|null
  public company!:string|null
  public initials!:string|null
}

export default class Issue extends ServiceItem {
  public type!: number
  public company!:Company|null
  public solver_id:number
  public solver_company_id:number
  public user: User = new User()
  public element_id?: number
  public room_id?: number
  public media:MediaItem[] = []
  public files:MediaItem[] = []
  public taggedImages:MediaItem[] = []
  public signature!: File
  public comments: ServiceComment[] = []
  public appointment_at?: moment.MomentInput|string|null
  constructor () {
    super()
    this.type = 1
    this.comments = []
    this.user_id = 0
    this.user = new User()
    this.files = []
    this.solver_company_id = 0
    this.solver_id = 0
  }
}

export const IssueTypes = Object.freeze({
  SERVICE: {
    name: 'Servicemelding',
    value: 1
  },
  DELIVERY: {
    name: 'PVO',
    value: 4
  },
  SURVEY: {
    name: 'Keuring',
    value: 5
  },
  INSPECTION: {
    name: 'Voorschouw',
    value: 6
  },
  RENTAL: {
    name: 'Huurmelding',
    value: 7
  },
  EXTERNAL_INSPECTION: {
    name: 'Externe inspectie',
    value: 8
  }
})
