






































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import MediaItem, { Tag } from '@/types/mediaItem'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class Filelist extends Vue {
  @Prop() mediaItems!:MediaItem[]
  @Prop({ default: false }) permissionToDelete!:boolean
  @Prop({ default: false }) showEditBtn!:boolean
  @Prop({ default: false }) showDownloadBtn!:boolean
  @Prop({ default: false }) showOrderBtn!:boolean
  @Prop({ default: false }) showPublishBtn!:boolean
  @Prop({ default: null }) filter!:{ key:string, value: string|number }|null

  showPreviewModal = false
  showFileSettingsModal = false
  showReadByModal = false
  showPublishModal = false
  file:MediaItem|null = null
  fileToEdit:MediaItem|null = null
  fileToPublish:{ id:number, name:string, important:boolean }|null = null
  removedFiles:MediaItem[] = []

  get mediaFiles () : MediaItem[] {
    return this.mediaItems.filter(mediaItem => !this.removedFiles.includes(mediaItem))
  }

  togglePreviewModal (file:MediaItem|null) : void {
    if (!this.showPreviewModal && file) {
      if (file.type === 'application/pdf') {
        window.open(file.url, '_blank')
      } else {
        this.file = file
        this.showPreviewModal = true
      }
    } else {
      this.file = null
      this.showPreviewModal = false
    }
  }

  toggleFileSettingsModal (file:MediaItem|null) : void {
    if (!this.showFileSettingsModal && file) {
      this.fileToEdit = file
      this.showFileSettingsModal = true
    } else {
      this.fileToEdit = null
      this.showFileSettingsModal = false
    }
  }

  toggleReadByModal (file:MediaItem|null) : void {
    if (!this.showReadByModal) {
      this.file = file
      this.showReadByModal = true
    } else {
      this.file = null
      this.showReadByModal = false
    }
  }

  togglePublishModal (file:MediaItem|null = null) : void {
    this.showPublishModal = !this.showPublishModal

    if (this.showPublishModal && file) {
      this.fileToPublish = {
        id: file.id,
        name: file.file_name,
        important: false
      }
    } else {
      this.fileToPublish = null
    }
  }

  publishFile () : void {
    if (this.fileToPublish) {
      axios.post(process.env.VUE_APP_API_URL + '/media/' + this.fileToPublish.id + '/publish', { important: this.fileToPublish.important }).then(() => {
        this.togglePublishModal()
        notification.success({ message: 'Bestand gepubliceerd.', description: 'Het bestand is succesvol gepubliceerd.', duration: 3 })
        this.$emit('filePublished')
      }).catch(() => {
        notification.error({ message: 'Publiceren mislukt.', description: 'Er is iets mis gegaan. Probeer het later nog eens.' })
      })
    }
  }

  renameFile () : void {
    if (this.fileToEdit) {
      axios.post(process.env.VUE_APP_API_URL + '/media/' + this.fileToEdit.id + '/rename', { name: this.fileToEdit.name }).then(() => {
        this.toggleFileSettingsModal(null)
        notification.success({ message: 'Naam van bestand bijgewerkt.', description: 'De naam van het bestand is succesvol opgeslagen.', duration: 3 })
        this.$emit('fileRenamed')
      }).catch(() => {
        notification.error({ message: 'Bijwerken mislukt.', description: 'De naam van het bestand is NIET opgeslagen. Probeer het later nog eens.' })
      })
    }
  }

  downloadFile (mediaItem:MediaItem) : void {
    axios.get(process.env.VUE_APP_API_URL + '/media/' + mediaItem.id + '/download', { responseType: 'arraybuffer' }).then(r => {
      const blob = new Blob([r.data], { type: mediaItem.type })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.target = '_blank'
      link.click()
    })
  }

  deleteMediaItem (file:MediaItem) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/media/' + file.id).then(() => {
      this.$emit('fileDeleted')
    }).then(() => this.removedFiles.push(file))
  }

  orderChanged (mediaItem:MediaItem) : void {
    axios.post(process.env.VUE_APP_API_URL + '/media/' + mediaItem.id + '/order', { order: mediaItem.order }).then(() => {
      notification.success({ message: 'Volgorde bijgewerkt.', description: 'De volgorde is succesvol opgeslagen.', duration: 3 })
    })
  }

  openPinItem (tag:Tag) : void {
    if (tag.tagged_issue_id && this.authUser.permissions.includes('issues.edit')) {
      const route = this.$router.resolve({ name: 'issueEdit', params: { issueId: tag.tagged_issue_id.toString() } })
      window.open(route.href, '_blank')
    } else if (tag.survey_id && this.authUser.permissions.includes('surveys.edit')) {
      const route = this.$router.resolve({ name: 'surveyEdit', params: { surveyId: tag.survey_id.toString() } })
      window.open(route.href, '_blank')
    }
  }

  getTagClass (tag:Tag) : string {
    if (tag.tagged_issue_id) {
      return tag.tagged_issue?.closed ? 'issue solved' : 'issue'
    } else if (tag.tagged_media_id) {
      return 'media'
    }

    return ''
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }
}
