



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class Tagger extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean
  @Prop({ type: String, default: 'right', validator: (value: string) => ['left', 'right'].includes(value) }) alignment!: string
  @Prop({ type: Array, default: () => [] }) tags!: string[]
  @Prop({ type: String, default: 'Tag Toevoegen' }) placeholder!: string
  @Prop({ required: false, default: null }) model!: string|null
  @Prop({ required: false, default: null }) modelId!: number|null

  @Watch('tags', { immediate: true, deep: true })
  onTagsChange (tags: string[]): void {
    this.selectedTags = tags
  }

  lookupTags: string[] = []
  selectedTags: string[] = []
  newTag = ''
  searchTagsTimeout: number|null = null

  removeTag (selectedTags: string[]): void {
    this.selectedTags = selectedTags
    this.$emit('change', this.selectedTags)
  }

  handleSearch (value: string): void {
    // wait 600ms before fetching tags, cancel previous request
    // fetching tags is done with this.searchTags
    if (this.searchTagsTimeout !== null) {
      clearTimeout(this.searchTagsTimeout)
    }
    this.searchTagsTimeout = setTimeout(() => {
      this.searchTags(value)
    }, 600)
  }

  searchTags (value: string): void {
    if (value.trim() === '') {
      this.lookupTags = []
      return
    }

    this.isFetchingTags = true
    axios.post(`${process.env.VUE_APP_API_URL}/tags/${this.model}/search`, { query: value })
      .then(response => {
        // tags come in as an array of { id: number, name: string }, we only need the name
        this.lookupTags = response.data.map((tag: { id: number, name: string }) => tag.name)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.isFetchingTags = false
      })
  }

  addTag (): void {
    if (this.newTag.trim() === '' || this.selectedTags.filter(tag => tag.toLowerCase() === this.newTag.toLowerCase()).length > 0) {
      return
    }
    this.selectedTags.push(this.capitalizeFirstLetterOfEachWord(this.newTag))
    this.$emit('change', this.selectedTags)
    this.newTag = ''
  }

  isFetchingTags = false
  updateTags (): void {
    if (this.isFetchingTags || this.lookupTags.length > 0) {
      return
    }

    this.isFetchingTags = true
    const url = this.model !== null ? `${process.env.VUE_APP_API_URL}/tags/recommended/${this.model}/${this.modelId}` : `${process.env.VUE_APP_API_URL}/tags/recent`
    axios.get(url)
      .then(response => {
        this.lookupTags = response.data as string[]
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.isFetchingTags = false
      })
  }

  getAlignment (): string {
    return this.alignment === 'left' ? 'row' : 'row-reverse'
  }

  get filteredTags (): string[] {
    const tags = this.newTag.trim() !== '' ? [this.newTag].concat(this.lookupTags) : this.lookupTags
    return tags.filter(tag => !this.selectedTags.map(t => t.toLowerCase()).includes(tag.toLowerCase()))
      .filter(tag => tag.toLowerCase().includes(this.newTag.toLowerCase()))
  }

  capitalizeFirstLetterOfEachWord (str: string): string {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
