











import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import 'moment/locale/nl'
import Layout from '@/types/layout'
import CopyLayoutBudgets from '@/views/layout/components/CopyLayoutBudgets.vue'
moment.locale('nl')

@Component({
  components: { CopyLayoutBudgets }
})

export default class LayoutBulkUpdater extends Vue {
  @Prop() selectedLayoutIds!:number[]
  @Prop() selectedLayouts!:Layout[]
}
