






















































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Column from '@/types/column'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'

@Component({
  components: { Uploader, TextEditor }
})

export default class ProjectEditOptionSettingsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Optie - Commerciële naam',
      key: 'name',
      scopedSlots: { customRender: 'name' }
    },
    {
      title: 'Standaard budget',
      key: 'option_total_budget',
      scopedSlots: { customRender: 'option_total_budget' }
    },
    {
      title: 'Project budget',
      key: 'project_option_total_budget',
      scopedSlots: { customRender: 'project_option_total_budget' }
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_options.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'BTW',
      key: 'project_options.vat_rate',
      scopedSlots: { customRender: 'vat_rate' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  downloading = false
  resetting = false
  modalVisible = false
  projectOptions = {
    modular: [],
    carcass: [],
    completion2: [],
    completion3: []
  }

  defaultOptionValues:{ [key: string]: string|number|null } = {
    project_id: null,
    subelement_id: null,
    subelement_position: null,
    option_id: null,
    external_ref: null,
    name: null,
    name_sales: null,
    base_price: null,
    base_purchase_price: null,
    project_option_name: null,
    project_option_name_sales: null,
    project_price: null,
    project_purchase_price: null,
    project_text_sales: null,
    project_text_bim: null,
    project_default: 0,
    phase: 0,
    vat_rate: null
  }

  currentOption = this.defaultOptionValues

  mounted () : void {
    if (this.currentItem.id) {
      this.getProjectOptions()
    }
  }

  getProjectOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options').then((res) => {
      this.projectOptions = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  showModal (item:Option, subelement:{id:number, position:number}|undefined = undefined) : void {
    this.modalVisible = true
    this.currentOption = {
      project_id: this.currentItem.id,
      subelement_id: subelement ? subelement.id : null,
      subelement_position: subelement ? subelement.position : null,
      option_id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      name_sales: item.name_sales,
      base_price: item.price,
      base_purchase_price: item.purchase_price,
      project_option_name: item.project_options && item.project_options.name !== null ? item.project_options.name : item.name,
      project_option_name_sales: item.project_options && item.project_options.name_sales !== null ? item.project_options.name_sales : item.name_sales,
      project_price: item.project_options && item.project_options.price !== null ? item.project_options.price : item.price,
      project_purchase_price: item.project_options && item.project_options.purchase_price !== null ? item.project_options.purchase_price : item.purchase_price,
      project_text_sales: item.project_options && item.project_options.text_sales !== null ? item.project_options.text_sales : item.text_sales,
      project_text_bim: item.project_options && item.project_options.text_bim !== null ? item.project_options.text_bim : item.text_bim,
      project_default: item.project_options && item.project_options.default !== null ? item.project_options.default : 0,
      vat_rate: item.project_options && item.project_options.vat_rate !== null ? item.project_options.vat_rate : 21,
      phase: item.phase
    }
  }

  hideModal () : void {
    this.modalVisible = false
    this.currentOption = this.defaultOptionValues
  }

  storeOption () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options', this.currentOption).then((res) => {
      this.projectOptions = res.data
      this.hideModal()
      notification.success({ message: 'Optie instellingen opgeslagen.', description: 'De optie instellingen zijn succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Optie instellingen NIET opgeslagen.', description: 'De optie instellingen zijn NIET opgeslagen.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  resetProjectOption (item:{ project_options:{ id:number } }) : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options/' + item.project_options.id + '/reset').then((res) => {
      this.projectOptions = res.data
      notification.success({ message: 'Optie instelling gereset.', description: 'De optie instelling is succesvol gereset.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Optie instellingen NIET gereset.', description: 'De optie instelling is NIET gereset.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  contentChanged (key:string, value:string) : void {
    this.currentOption[key] = value
  }

  resetProjectOptions () : void {
    this.resetting = true
    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options/reset').then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }).finally(() => {
      this.resetting = false
    })
  }

  downloadProjectOptions () : void {
    this.downloading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options/export').then((res) => {
      window.open(res.data, '_blank')
    }).finally(() => {
      this.downloading = false
    })
  }

  newFileUploaded (file:File) : void {
    this.$message.info('Upload gestart..', 3)

    const formData = new FormData()
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/options/import', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      notification.success({ message: 'Upload succesvol.', description: 'De projectopties zijn succesvol opgeslagen.', duration: 3 })
      this.getProjectOptions()
    }).catch(() => {
      notification.error({ message: 'Upload mislukt.', description: 'De projectopties zijn NIET opgeslagen. Probeer het later nog eens.' })
    })
  }

  openBudgetTab () : void {
    this.$emit('changeTab', { tab: 'budgets', subTab: 'project_option_budgets' })
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get canResetProjectOptions () : boolean {
    return (this.currentItem.phase === 1 && this.authUser.permissions.includes('projects.projectOptions_reset.edit')) || this.authUser.roleNames.includes('developer')
  }
}
