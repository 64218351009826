















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Layout from '@/types/layout'

@Component({
  components: {}
})

export default class LayoutAnalyticsTab extends Vue {
  settings = {
    routeNames: { list: 'layouts', show: 'layoutShow', edit: 'layoutEdit' },
    routeParamKey: 'layoutId',
    functionNames: { setCurrent: 'setCurrentLayout', clearCurrent: 'clearCurrentLayout', save: 'saveLayout' },
    getterNames: { current: 'currentLayout', loading: 'loadingLayout' }
  }

  get currentItem () : Layout {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
