











































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Feedback, { FeedbackClasses } from '@/types/feedback'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'
import Release, { PageModes } from '@/types/release'
import { Status } from '@/types/serviceItem'
import { notification } from 'ant-design-vue'
import ReleaseModel from '@/models/ReleaseModel'
import { isEmpty } from 'lodash'
import axios from 'axios'

@Component({
  components: { UploadQueue, Uploader, TextEditor }
})

export default class ReleaseDetails extends Vue {
  @Prop({ default: PageModes.create }) mode!: string
  @Prop({ default: false, type: Boolean }) latest!: boolean

  currentMode = PageModes.create
  release = new Release()
  type = 'release'
  selectedFeedbackId = 0
  selectedMonth = 0
  selectedYear = 0
  hasChanges = false
  loadingFeedbacks = false
  saving = false
  publishing = false
  loading = false
  feedbacks: Feedback[] = []

  mounted () : void {
    this.currentMode = this.mode
    this.selectedMonth = new Date().getMonth()
    this.selectedYear = new Date().getFullYear()

    this.getFeedbacks()

    if (this.currentMode !== PageModes.create) {
      this.getRelease()
    }
  }

  get page () : { title: string, subtitle: string } {
    let title = 'Release ' + this.release.id
    let subtitle = 'Bekijk en bewerk de details van de release'

    switch (this.currentMode) {
      case PageModes.create:
        title = 'Nieuwe release'
        subtitle = 'Maak een nieuwe release aan'
        break
      case PageModes.edit:
        subtitle = `Release ${this.release.id} bewerken`
        break
      case PageModes.view:
        subtitle = `Release ${this.release.id} bekijken`
        break
    }

    return { title, subtitle }
  }

  get years (): number[] {
    return Array.from({ length: 10 }, (v, k) => new Date().getFullYear() - k)
  }

  get months (): string[] {
    return Array.from({ length: 12 }, (v, k) => new Date(0, k - 1).toLocaleString('default', { month: 'long' }))
  }

  get validFeedbacks (): Feedback[] {
    return this.feedbacks
  }

  get canSave (): boolean {
    return !isEmpty(this.release.version) && this.canEdit
  }

  get isPublished (): boolean {
    return !isEmpty(this.release.released_at)
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('releases.edit')
  }

  get editable (): boolean {
    return (this.currentMode === PageModes.edit || this.currentMode === PageModes.create)
  }

  get sortedFeedbacks (): Feedback[] {
    // return sorted release feedbacks by id
    return this.release.feedbacks.sort((a: Feedback, b: Feedback) => a.id - b.id)
  }

  enableEditing () : void {
    this.$router.push({ name: 'releaseEdit', params: { releaseId: this.release.id.toString() } })
    this.currentMode = PageModes.edit
  }

  capitalize (str: string) : string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  addValidFeedbacks () : void {
    this.release.feedbacks = []
    this.validFeedbacks.forEach(feedback => this.addFeedback(feedback))
  }

  addFeedback (feedback : Feedback) : void {
    this.release.feedbacks.push(feedback)
    this.selectedFeedbackId = 0
    // Add the feedback solution to the logs
    this.release.logs += this.parseFeedbackToLog(feedback)
  }

  removeFeedback (id: number) : void {
    this.release.feedbacks = this.release.feedbacks.filter(fid => fid.id !== id)
  }

  getStatus (id: number):Status {
    return FeedbackClasses.filter(s => s.id === id)[0] || FeedbackClasses[0]
  }

  feedbackList!: {
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  }

  // Get feedbacks from the api
  async getFeedbacks (): Promise<void> {
    this.loadingFeedbacks = true

    this.release.version = this.getVersion(this.selectedMonth, this.selectedYear)

    // First we select the previous month, then in the payload we add 1 to the month for the correct api month
    const feedbackMonth = new Date(this.selectedYear, this.selectedMonth - 1, 1).getMonth()

    const payload = {
      month: feedbackMonth + 1,
      year: this.selectedYear
    }

    axios.post(`${process.env.VUE_APP_API_URL}/releases/feedbacks`, payload)
      .then((res) => {
        this.feedbacks = res.data.data as Feedback[]
        this.addValidFeedbacks()
      }).catch((err) => {
        console.log(err)
        notification.error({
          message: 'Fout tijdens het ophalen van de feedbacks!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 3
        })
      }).finally(() => {
        this.loadingFeedbacks = false
      })
  }

  save () : void {
    if (this.currentMode === PageModes.create) {
      this.store()
    } else {
      this.update()
    }
  }

  store () : void {
    this.saving = true
    const releaseModel = new ReleaseModel(this.release)
    releaseModel.save().then((release) => {
      this.release = release as unknown as Release
      notification.success({
        message: 'Release opgeslagen!',
        description: 'Release is succesvol opgeslagen.',
        duration: 3
      })
      this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
      this.currentMode = PageModes.view
    }).catch((err) => {
      console.log(err)
      this.$message.error('Release kon niet worden opgeslagen. Check de console voor meer informatie')
    }).finally(() => {
      this.saving = false
    })
  }

  update () : void {
    this.saving = true
    const releaseModel = new ReleaseModel(this.release)
    releaseModel.patch().then(() => {
      notification.success({
        message: 'Release bijgewerkt! ',
        description: 'Release is succesvol opgeslagen.',
        duration: 3
      })
      this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
      this.currentMode = PageModes.view
    }).catch((err) => {
      console.log(err)
      notification.error({
        message: 'Release niet opgeslagen!',
        description: 'Release kon niet worden opgeslagen. Check de console voor meer informatie',
        duration: 3
      })
    }).finally(() => {
      this.saving = false
    })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  private getRelease () {
    this.loading = true
    if (this.latest) {
      this.getLatestRelease()
    } else {
      this.getReleaseById()
    }
    this.loading = false
  }

  private async getReleaseById () {
    await ReleaseModel.find(this.$route.params.releaseId).then((release) => {
      // Typecast to Release
      this.release = release as unknown as Release
      this.release = this.release.data
      // this.activeLogSections = this.getAllLogSectionKeysWithContent()
    }).catch((err) => {
      console.log(err)
      notification.error({
        message: 'Fout tijdens het ophalen van de release!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
        duration: 3
      })
    })
  }

  private async getLatestRelease (): Promise<void> {
    await axios.get(`${process.env.VUE_APP_API_URL}/releases/latest`)
      .then((res) => {
        this.release = res.data.data
      }).catch((err) => {
        let description = 'Er is iets mis gegaan. Probeer het later nog een keer.'
        if (err.response.status === 404) {
          description = 'Release info niet gevonden.'
        }
        notification.error({
          message: 'Fout tijdens het ophalen van de release!',
          description: description,
          duration: 3
        })
      })
  }

  publish () : void {
    this.publishing = true
    axios.get(`${process.env.VUE_APP_API_URL}/releases/${this.release.id}/publish`)
      .then((res) => {
        this.release = res.data.data
        notification.success({
          message: 'Release gepubliceerd',
          description: 'Release is succesvol gepubliceerd.',
          duration: 3
        })
        this.$router.push({ name: 'releaseShow', params: { releaseId: this.release.id.toString() } })
        this.currentMode = PageModes.view
      }).catch((err) => {
        console.log(err)
        notification.error({
          message: 'Release niet gepubliceerd',
          description: 'Release kon niet worden gepubliceerd, check de console voor meer informatie.',
          duration: 3
        })
      }).finally(() => {
        this.publishing = false
      })
  }

  parseFeedbackToLog (feedback: Feedback) : string {
    const header = `<b>#${feedback.id} - ${feedback.title}</b>`
    const solution = feedback.solution || 'Geen oplossing aangegeven.'
    return `${header}<br>${solution}<br><br>`
  }

  getVersion (month: number, year: number) : string {
    return `${this.capitalize(this.months[month])} ${year}`
  }
}
