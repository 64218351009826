


















import { Vue, Component, Prop } from 'vue-property-decorator'
import Issue from '@/types/issue'
import TextEditor from '@/components/base/TextEditor.vue'
import storeSettings from '@/types/storeSettings'
import moment from 'moment/moment'

@Component({
  components: { TextEditor }
})

export default class PrioritySelector extends Vue {
  @Prop({ required: true }) settings!: storeSettings
  @Prop({ required: true, type: Boolean, default: false }) editable!: boolean

  moment = moment

  get currentItem (): Issue {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get loading (): boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  disabledDate (currentDate: moment.Moment): boolean {
    return currentDate < moment().endOf('day')
  }
}
